import * as React from 'react'
// import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DestinationInnPart from '../components/home/destinationInn'
import StyledPOne from '../components/typography/ParaOne'

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

const LinksWrapper = styled.div`
  margin-bottom: 64px;

  .innerWrapper {
    margin: 0 auto;
    max-width: var(--site-width);

    text-align: center;
    display: grid;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .col {
    text-align: center;

    p {
      padding-top: 0;
      margin-top: 0;
    }

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;

      li {
        margin-top: 0;
      }
    }
  }

  .links {
    color: black;
  }
`

const Links = () => (
  <Layout>
    <Seo
      title="What's near us?"
      description="Links to golf courses, activities and some tourism info for our area."
      canonical="https://destinationinn.ca/links/"
    />

    <StaticImage
      layout="fullWidth"
      loading="eager"
      quality={100}
      formats={['AUTO', 'WEBP', 'AVIF']}
      src="../images/rockies.jpg"
      alt="placeholder picture of the rockies"
      style={{ minHeight: `300px` }}
    />

    <Wrapper>
      <DestinationInnPart title="LINKS" lastLine="" />
    </Wrapper>

    <LinksWrapper>
      <div className="innerWrapper">
        <div className="col colOne">
          <StyledPOne text="GOLF COURSES" />
          <ul>
            <li>
              <a
                className="links"
                href="https://copperpointgolf.com/"
                title="Copper Point"
                target="_blank"
                rel="noopener noreferrer"
              >
                Copper Point
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.eagleranchresort.com/"
                title="Eagle Ranch"
                target="_blank"
                rel="noopener noreferrer"
              >
                Eagle Ranch
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.greywolfgolf.com/"
                title="Greywolf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Greywolf
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.radiumgolf.ca/"
                title="Radium Resort"
                target="_blank"
                rel="noopener noreferrer"
              >
                Radium Resort
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.fairmonthotsprings.com/"
                title="Fairmont Resort"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fairmont Resort
              </a>
            </li>
            <li>
              <a
                className="links"
                href="http://www.windermerevalleygolfcourse.com/"
                title="Windermere Valley Golf Course"
                target="_blank"
                rel="noopener noreferrer"
              >
                Windermere Valley Golf Course
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.radiumgolf.ca/the-courses/the-springs-course/"
                title="The Springs Course"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Springs Course
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://golfgolden.com/index.cfm?"
                title="Golden Golf Club"
                target="_blank"
                rel="noopener noreferrer"
              >
                Golden Golf Club
              </a>
            </li>
          </ul>
        </div>
        <div className="col colTwo">
          <StyledPOne text="ACTIVITIES" />
          <ul>
            <li>
              <a
                className="links"
                href="https://www.radiumhotsprings.com/"
                title="Radium Hot Springs"
                target="_blank"
                rel="noopener noreferrer"
              >
                Radium Hot Springs
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://raftingtherockies.com/"
                title="Kootenay River Runners"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kootenay River Runners
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://alpinerafting.com/"
                title="Alpine Rafting"
                target="_blank"
                rel="noopener noreferrer"
              >
                Alpine Rafting
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://valleyzip.com/"
                title="Valley Zip Line Adventure"
                target="_blank"
                rel="noopener noreferrer"
              >
                Valley Zip Line Adventure
              </a>
            </li>
          </ul>
        </div>
        <div className="col colThree">
          <StyledPOne text="TOURISM INFO" />
          <ul>
            <li>
              <a
                className="links"
                href="https://www.radiumhotsprings.com/"
                title="Tourism Radium"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tourism Radium
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://columbiavalley.com/"
                title="Invermere.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Invermere.com
              </a>
            </li>
            <li>
              <a
                className="links"
                href="http://www.bcadventure.com/"
                title="BC Adventure"
                target="_blank"
                rel="noopener noreferrer"
              >
                BC Adventure
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.hellobc.com/"
                title="HelloBC"
                target="_blank"
                rel="noopener noreferrer"
              >
                HelloBC
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.cvchamber.ca/"
                title="Columbia Valley Chamber of Commerce"
                target="_blank"
                rel="noopener noreferrer"
              >
                Columbia Valley Chamber of Commerce
              </a>
            </li>
          </ul>
        </div>
      </div>
    </LinksWrapper>
  </Layout>
)

export default Links
